<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni del Log</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'tools-log-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Lista Log
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>

    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
      <b-card>
        <b-card-header class="pt-0 pl-0">
          <b-card-title>Level: <strong class="text-uppercase" :class="trBackgroundColor(datiLog.level)">{{ datiLog.level }}</strong></b-card-title>
        </b-card-header>

        <b-card-body class="px-0 py-0">
          <b-row>
            <b-col cols="12">
              <showdata field_name="Data e ora di creazione" :field_value="formattazioneDataOra(datiLog.created_at, 'it')" />
            </b-col>
            <b-col cols="12">
              <showdata field_name="Messaggio" :field_value="datiLog.message" />
            </b-col>
            <b-col cols="12" md="9" lg="10">
              <showdata field_name="File" :field_value="datiLog.file" />
            </b-col>
            <b-col cols="12" md="3" lg="2">
              <showdata field_name="Linea" :field_value="datiLog.line" />
            </b-col>
            <b-col cols="12">
              <showdata field_name="Extra" :field_value="datiLog.extra" />
            </b-col>
            <b-col cols="12">
              <showdata field_name="Stack trace" :field_value="datiLog.stack_trace" />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </div>


  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import moment from 'moment'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    BBadge,

    showdata,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      test: {"id":1,"name":"A green door","price":12.50,"tags":["home","green"]},
      datiLog: {
        id: '',
        message: '',
        level: '',
        exception_message: '',
        file: '',
        created_at: '',
        line: '',
        context: {},
        extra: [],
        stack_trace: '',
      },
    }
  },
  created() {
    this.$http.get('api/crm/log/card/'+router.currentRoute.params.id_riga).then(response => {
        if(response.data.statusCode == 200){
          this.datiLog = response.data.reply.logData

          this.Caricato = true;
          this.errore_caricamento = false;
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      })
    },
    filters: {
      pretty: function(value) {
        return JSON.stringify(JSON.parse(value), null, 2);
      }
    },
    methods: {
      formattazioneDataOra(created_at,lang) {
        if (created_at) {
          return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
        } else {
          return '---'
        }
      },
      trBackgroundColor(level) {
        if ((level == 'error') || (level == 'critical') || (level == 'alert') || (level == 'emergency')) {
          return 'text-danger';
        } else if ((level == 'warning')) {
          return 'text-warning';
        } else {
          return '';
        }
      },
    }
  }
</script>